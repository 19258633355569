import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CorpContext } from '../reducers/Main'
import {
  Grid,
  CssBaseline,
  Drawer,
  Hidden,
  List,
  ListItem,
  Divider,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Icon,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AlertBar from '../view/Snackbar/AlertBar'
import ErrorDialog from '../view/Dialog/ErrorDialog'
import Auth from '../model/Auth'
import { mainStyle } from '../styles'
import { clearToken, queryInfo } from '../api'

function eraseCookieFromAllPaths() {
  const cookies = document.cookie.split('; ')
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.')
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path='
      const p = window.location.pathname.split('/')
      document.cookie = cookieBase + '/'
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/')
        p.pop()
      }
      d.shift()
    }
  }
}

function Main(props) {
  const classes = mainStyle()
  const { state, dispatch } = useContext(CorpContext)
  const { container, children } = props
  const [mobileOpen, setMobileOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [appBarWidth, setAppBarWidth] = useState('')
  const [selectedPath, setSelectPath] = useState(window.location.pathname)
  const [showCompanyAccountChangeNotification, setShowCompanyAccountChangeNotification] = useState(true)

  const isAdmin = state.me?.[0]?.permission.includes('ADMIN')

  useEffect(() => {
    handleSelectedPath(selectedPath)
    state.me.map((el) => {
      if (state.regiseter) {
        setAppBarWidth('')
      } else {
        setAppBarWidth(classes.appBarWidth)
      }
    })
    console.log(state.me)
    return () => {}
  }, [state.me, selectedPath])

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisible, false)
    return () => {}
  }, [])

  const handleVisible = async () => {
    if (document.visibilityState !== 'hidden') {
      const data = await queryInfo('users(id:"me")')
      if (data.status === 401) {
        dispatch({ type: 'UPDATE_LOGOUT', isLogout: true })
      }
    }
  }

  function handleClick(type, event) {
    switch (type) {
      case 'profile':
        setAnchorEl(event.currentTarget)
        if (event.currentTarget.id === 'logout') {
          setOpen(true)
        }
        break
      case 'contact':
        window.open(
          `${process.env.REACT_APP_APP_URI}/cs/cs_center_guest?cs_id=360007935934`,
          '_blank'
        )
        break
      case 'email':
        window.open(
          'mailto:business@linego.me?subject=LINE TAXI 企業簽單',
          '_blank'
        )
        break

      default:
        break
    }
  }

  async function handleClose(event) {
    setAnchorEl(null)
    setOpen(false)
    if (event.currentTarget.id === 'logout') {
      const data = await clearToken('token')
      console.log(data, 'clearToken')
      if (data.status == 200) {
        window.location.href = window.location.pathname
      }
    }
  }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  function handleSelectedPath(path) {
    setSelectPath(path)
  }

  const drawer = () => {
    return (
      <div>
        <div className={classes.toolbar}>
          <img
            className={classes.businessLogoIcon}
            src="https://static.taxigo-cdn.com/icons/business/line-go-for-business.svg"
          />
        </div>
        <Divider />
        <List className={classes.drawerList}>
          {Auth.getList(state).map((item) => (
            <div key={item.id} className={classes.listWrap}>
              <ListItem
                key={item.id}
                component={Link}
                to={item.path}
                className={`${classes.drawerItem}
								${item.path === selectedPath && classes.drawerHighLight}
									${!item.path && classes.blockEvent}`}
                onClick={() => handleSelectedPath(item.path)}
              >
                <div className={classes.drawerItemText}>{item.id}</div>
              </ListItem>
              {item.sub &&
                item.sub.map((item) => (
                  <ListItem
                    key={item.id}
                    component={Link}
                    to={item.path}
                    className={`${classes.subDrawerItem} ${
                      item.path === selectedPath && classes.drawerHighLight
                    }`}
                    onClick={() => handleSelectedPath(item.path)}
                  >
                    <div className={classes.subDrawerItemText}>{item.id}</div>
                  </ListItem>
                ))}
            </div>
          ))}
        </List>
      </div>
    )
  }

  const handleRenderDrawer = () => {
    if (state.regiseter) return
    return (
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer()}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer()}
          </Drawer>
        </Hidden>
      </nav>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar className={`${classes.appBar} ${appBarWidth}`}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Grid
              container
              justify="space-between"
              className={classes.appBarContent}
            >
              <Grid>
                <Typography variant="h6" noWrap>
                  <Link to="/" className={classes.link}>
                    <img
                      className={classes.businessIcon}
                      src="https://static.taxigo-cdn.com/icons/business/lt-business.svg"
                    />
                    {(state.company[0] && state.company[0].name) || ''}
                  </Link>
                </Typography>
              </Grid>
              <Grid>
                <div className={classes.toolbarAction}>
                  <Icon
                    className={classes.icon}
                    style={{ fontSize: '20px' }}
                    onClick={(e) => handleClick('profile', e)}
                  >
                    <img
                      className={classes.headIcon}
                      src="https://static.taxigo-cdn.com/icons/business/profile.svg"
                    />
                  </Icon>
                  <Icon
                    className={classes.icon}
                    style={{ fontSize: '20px' }}
                    onClick={(e) => handleClick('contact', e)}
                  >
                    <img
                      className={classes.headIcon}
                      src="https://static.taxigo-cdn.com/icons/business/cs.svg"
                    />
                  </Icon>
                  <Icon
                    className={classes.icon}
                    style={{ fontSize: '20px' }}
                    onClick={(e) => handleClick('email', e)}
                  >
                    <img
                      className={classes.headIcon}
                      src="https://static.taxigo-cdn.com/icons/business/email.svg"
                    />
                  </Icon>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <MenuItem
                      id="profile"
                      onClick={handleClose}
                      component={Link}
                      to={`/profile`}
                    >
                      個人檔案
                    </MenuItem>
                    <MenuItem
                      id="logout"
                      onClick={(e) => handleClick('profile', e)}
                    >
                      登出
                    </MenuItem>
                  </Menu>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      確認要登出企業簽單系統
                    </DialogTitle>
                    <DialogActions>
                      <Button id="cnacel" onClick={handleClose} color="primary">
                        取消
                      </Button>
                      <Button
                        id="logout"
                        onClick={handleClose}
                        color="primary"
                        autoFocus
                      >
                        確認
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {handleRenderDrawer()}
        <main className={classes.content}>
          <div id="main_container" className={classes.appBarContent}>
            {children}
          </div>
        </main>
      </div>
      <AlertBar />
      <ErrorDialog
        open={state.isLogout}
        message="您已被登出，請重新登入"
        confirmText="重新登入"
        handleClose={() => {
          window.location.href = window.location.pathname
        }}
      />

      {/* 2024/12/10 公告虛擬帳號切換台新 pop up，之後可移除 */}
      <Dialog
        open={showCompanyAccountChangeNotification && isAdmin}
        onClose={() => { setShowCompanyAccountChangeNotification(false) }}
      >
        <img src="https://static.taxigo-cdn.com/icons/general/wallet.svg" height="80px" style={{ marginTop: '20px' }} />
        <DialogTitle id="alert-dialog-title">重要通知！</DialogTitle>
        <DialogContent>
          <b>親愛的 {state.me[0].name} 您好：</b><br />
          感謝貴公司一直以來對我們的支持與信任。為持續提升服務品質，並提供更便利與穩定的交易體驗，我們將對企業匯款帳戶進行調整。<br />
          <span style={{ color: 'red '}}>自 2024年12月10日起</span>，原使用之花旗銀行帳戶將正式更換為台新銀行帳戶。<br />
          <br />
          <b>請參閱以下新匯款資訊，並協助通知貴司財務人員，以確保交易順暢：</b><br />
          <ul style={{
            listStyleType: 'disc',
            paddingLeft: '20px',
          }}>
            <li>銀行：台新銀行（代碼：812）</li>
            <li>分行代碼：8120687</li>
            <li>帳號：{state.company?.[0]?.virtual_account_info?.accountNumber}</li>
          </ul>
          <br />
          如有任何疑問或需要進一步協助，請隨時與我們聯繫，我們將竭誠為您服務<br />
          電話：林小姐 02-7709 3362 ext.224<br />
          Email：<a href="mailto:business@linego.me">business@linego.me</a><br />
          企業簽單專屬官方LINE：@linegobiz<br />
          <br />
          再次感謝您對我們的支持與體諒，我們珍視與貴司的合作關係，期待持續為您提供更優質的服務。<br />
          敬祝 商祺<br />
          LINE GO<br />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', marginBottom: 12 }}>
          <Button variant="contained" onClick={() => {
            setShowCompanyAccountChangeNotification(false)
          }} style={{ backgroundColor: '#06C755', color: '#FFFFFF' }} disableElevation>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
      {/* 2024/12/10 公告虛擬帳號切換台新 pop up，之後可移除 */}
    </React.Fragment>
  )
}

export default Main
